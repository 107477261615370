export const tendencyIndex = {
  GenreTend: [
    {
      name: "SF/판타지",
      value: "SF,판타지",
    },
    {
      name: "잠입/범죄",
      value: "잠입,범죄",
    },
    {
      name: "코믹",
      value: "코믹",
    },
    {
      name: "추리",
      value: "추리",
    },

    {
      name: "스릴러",
      value: "스릴러",
    },
    {
      name: "미션",
      value: "미션",
    },
    {
      name: "공포",
      value: "공포",
    },
    {
      name: "어드벤처/모험",
      value: "어드벤처,모험",
    },
    {
      name: "19금",
      value: "19금",
    },

    {
      name: "미스테리",
      value: "미스테리",
    },

    {
      name: "드라마/감성",
      value: "드라마,감성",
    },
  ],
  // 잠시 사용하지 않음
  // questTend: [
  //   {
  //     name: "추리형",
  //     value: "추리형",
  //   },
  //   {
  //     name: "관찰형",
  //     value: "관찰형",
  //   },
  //   {
  //     name: "넌센스",
  //     value: "넌센스",
  //   },
  // ],
};
