const category = {
  GenreCategory: [
    { name: "전체", value: "전체" },
    {
      name: "미션",
      value: "미션",
    },

    {
      name: "코믹",
      value: "코믹",
    },
    {
      name: "추리",
      value: "추리",
    },
    {
      name: "공포",
      value: "공포",
    },
    {
      name: "19금",
      value: "19금",
    },
    {
      name: "스릴러",
      value: "스릴러",
    },
    {
      name: "미스테리",
      value: "미스테리",
    },
    {
      name: "잠입/범죄",
      value: "잠입,범죄",
    },

    {
      name: "SF/판타지",
      value: "SF,판타지",
    },

    {
      name: "드라마/감성",
      value: "드라마,감성",
    },
    {
      name: "어드벤처/모험",
      value: "어드벤처,모험",
    },
  ],

  LocationCategory: [
    { name: "전체", value: "전체" },
    {
      name: "강남",
      value: "강남",
    },
    {
      name: "홍대",
      value: "홍대",
    },
    {
      name: "건대",
      value: "건대",
    },
    {
      name: "신촌",
      value: "신촌",
    },
    {
      name: "대학로",
      value: "대학로",
    },
    {
      name: "강북",
      value: "강북",
    },
    {
      name: "신림",
      value: "신림",
    },
    {
      name: "서울(기타)",
      value: "서울(기타)",
    },
  ],

  PeopleCategory: [
    { name: "전체", value: "전체" },
    {
      name: "1인",
      value: "1",
    },
    {
      name: "2인",
      value: "2",
    },
    {
      name: "3인",
      value: "3",
    },
    {
      name: "4인",
      value: "4",
    },
    {
      name: "5인이상",
      value: "5",
    },
  ],
};

export default category;

//   ScoreCategory: [
//     {
//       name: "⭐️",
//       value: 1,
//     },
//     {
//       name: "⭐️⭐️",
//       value: 2,
//     },
//     {
//       name: "⭐️⭐️⭐️",
//       value: 3,
//     },
//     {
//       name: "⭐️⭐️⭐️⭐️",
//       value: 4,
//     },
//     {
//       name: "⭐️⭐️⭐️⭐️⭐️",
//       value: 5,
//     },
//   ],

//   DifficultyCategory: [
//     {
//       name: "전체",
//       value: "전체",
//     },
//     {
//       name: "🔒",
//       value: 1,
//     },
//     {
//       name: "🔒🔒",
//       value: 2,
//     },
//     {
//       name: "🔒🔒🔒",
//       value: 3,
//     },
//     {
//       name: "🔒🔒🔒🔒",
//       value: 4,
//     },
//     {
//       name: "🔒🔒🔒🔒🔒",
//       value: 5,
//     },
//   ],
